.newCard {
  border-radius: 55px;
  display: flex !important;
  justify-content: center;
  overflow: hidden;
  padding: 2px !important;
  position: relative;
  width: fit-content;

  &::before {
    animation: rotate 3s linear infinite;
    background: linear-gradient(278.1deg, #2167cf 7.59%, #b03aff 111.89%);
    content: '';
    display: block;
    height: 55px;
    position: absolute;
    top: -16px;
    width: 55px;
  }
  
  @keyframes rotate {
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(360deg);
    }
  }
}

.cardSelected {
  background: none !important;

  &::before {
    display: none;
  }
}

.cardInnerSelected {
  background: #ffffff26 !important;
}

.cardInner {
  align-items: center;
  background: #222;
  border-radius: 55px;
  color: #fff;
  display: flex;
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
  padding: 1.5px 7px !important;
  position: relative;
  width: 100%;
}

.icon { 
  color: #fff !important;
  fill: #fff !important;
  margin: 0 4px 0 0;
}

.smallBadge {
  right: 33px;
  top: -17px;

  .cardInner {
    padding: 0 6px !important;
  }
}


.light {
  background: none !important;

  .cardInner {
    background-blend-mode: darken;
    background-color: rgba(45, 108, 202, 0.08) !important;
    background-image: linear-gradient(#fff, #fff) !important;
    color: #2d6cca;
  }

  &::before {
    background: linear-gradient(258.77deg, #88b5f9eb 26.37%, #2d6cca 104.6%);      
  }

  .icon {
    color: #2d6cca !important;
    fill: #2d6cca !important;
  }
}
