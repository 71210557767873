@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import 'src/style/highlights';
@import 'src/style/colors';
@import 'src/style/fonts';

* {
  font-family: Inter, serif;

}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #f2f2f5 !important;
}

a {
  text-decoration: none;
}

.ant-message {
  z-index: 6 !important;
}

.settingsDrawer {
  z-index: 5 !important;

  .ant-drawer-header {
    padding-top: 31px;

    .ant-drawer-title {
      font-size: 24px;
      font-weight: 600;
    }
  }
}

.ant-page-header-back {
  font-size: $font-size-16;
  line-height: 1;
  margin-right: 5px !important;
}

.ant-notification {
  z-index: 9999 !important;
}

div.DraftEditor-root {
  background-color: $color-white;
  height: auto;
  margin: 0 42px -32px;
  min-height: 350px;
  padding: 70px;
  width: 816px;

  .public-DraftEditor-content {
    min-height: 350px;

    img {
      max-width: 100%;
      transition: box-shadow 0.2s ease;

      &:hover {
        box-shadow: 0 0 4px 0 $color-purple-medium;
      }
    }
  }
}

.rc-dialog-wrap {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 768px) {
    align-items: center;
  }
}

.rc-dialog-wrapModal {
  justify-content: unset !important;
}

.rc-dialog-mask {
  background-color: rgba(17, 18, 24, 0.7);
  z-index: 1000;
}

.rc-dialog-content {
  border-radius: 10px;
}

.notFound {
  height: 100%;
  text-align: center;

  .message,
  .button {
    margin-top: 26px;
  }

  .message {
    color: rgba(18, 18, 18, 0.6);
    font-size: $font-size-16;
  }

  .button {
    background: transparent;
    border: 1px solid #696777;
    border-radius: 5px;
    color: $color-gray-dark;
    padding: 0 35px;
  }
}

//Draft JS Block Formatting styles
.codeBlock {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: Inconsolata,Menlo,Consolas,monospace;
  font-size: $font-size-16;
  padding: 20px;
}

.headerOneWithTag,
.headerTwoWithTag,
.headerThreeWithTag,
.headerFourWithTag,
.headerFiveWithTag,
.headerSixWithTag,
.paragraphWithTag {
  margin-bottom: 14px;
  margin-top: 10px;
}

.newTextBlock {
  .public-DraftStyleDefault-block {
    background-color: #ebf7fe;
    border-radius: 5px;
    margin-left: -10px;
    margin-top: -10px;
    padding: 10px;
  }
}

.rc-tabs-dropdown {
  border-radius: 6px;
  z-index: 9999;

  .rc-tabs-dropdown-menu {
    .rc-tabs-dropdown-menu-item-active {
      background-color: #2d2f34;
      color: #ebf7fe;
      cursor: pointer;
    }
  }
}

.announcekit-widget-badge {
  font-size: 0 !important;

  &::before {
    animation-duration: unset !important;
    animation-fill-mode: unset !important;
    animation-iteration-count: unset !important;
    animation-name: none !important;
    animation-timing-function: unset !important;
    background: none !important;  
  }

  &::after {
    animation-duration: unset !important;
    animation-fill-mode: unset !important;
    animation-iteration-count: unset !important;
    animation-name: none !important;
    animation-timing-function: unset !important;
    background: none !important;  
  }

  svg {
    height: 20px !important;
    position: absolute;
    right: -10px;
    top: -32px;
    width: 20px !important;
    @media (max-height: 800px) {
      height: 34px !important;
    }
  }

  a {
    height: 0 !important;
    width: 0 !important;  
  }

  circle {
    fill: #f1aa3e !important;
  }
}

.drawerCloseIcon {
  .ant-drawer-content {
    overflow: visible;
  }

  .ant-drawer-header {
    background-color: rgb(242, 242, 245);
  }

  .ant-drawer-close {
    color: $color-white;
    left: -50px;
    opacity: 0.8;
    position: absolute;
    top: 40px;

    &:hover {
      opacity: 1;
    }
  }

  .closeIcon {
    font-size: 30px;
    right: 0;
  }
}

.customFigure {
  .rotateIcon {
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-name: ckw;
    cursor: not-allowed !important;
  }

  svg {
    cursor: pointer;
    margin-left: 10px;
    margin-right: -18px;
    opacity: 0;
    position: absolute;
    z-index: 1;
  }

  .crossIcon {
    margin-top: -5px;
  }

  .reLoadImg {
    margin-top: 22px;
  }

  .editAltImg {
    margin-top: 50px;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
}

@keyframes ckw {
  0% {
    transform: rotate(0deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
}

.recipientPopover {
  padding-top: 8px;

  .ant-popover-arrow {
    display: none !important;
  }
}

.locationDropdown {
  position: absolute !important;
  
  .rc-virtual-list-holder {
    &::-webkit-scrollbar {
      height: 5px !important;
      width: 5px !important;
    }
  
    &::-webkit-scrollbar-thumb {
      background: rgba(138, 138, 138, 0.32) !important;
    }
  
    &::-webkit-scrollbar-track {
      background: rgba(138, 138, 138, 0.12) !important;
    }
  }
}

.modal-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-right: 28px;
  
  .account-id-wrapper {
    color: #121212;
    font-family: 'Inter' sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-align: right;
  }
}

.force-graph-container {
  .graph-tooltip {
    background-color: transparent !important;
    z-index: 2;
    
    .threeDChartTooltip {
      background-color: #fff !important;
      border-radius: 5px;
      color: #121212 !important;
      padding: 10px;
  
      .pointsWrapper {
        display: flex;
        flex-direction: column !important;
        width: 350px;
      
        .singlePoint {
          display: flex;
        
          b {
            width: 120px;
          }
        }
      }
    }
  }
}

.scene-tooltip {
  z-index: 2;
  
  .threeDChartTooltip {
    background-color: #fff !important;
    border-radius: 5px;
    color: #121212 !important;
    padding: 10px;
  
    .pointsWrapper {
      display: flex;
      flex-direction: column !important;
      width: 350px;
  
      .singlePoint {
        display: flex;
        
        b {
          width: 120px;
        }
      }
    }
  }
}

.stickynav {
  background-color: #f9f9fb;
  margin-left: -20px;
  padding: 20px;
  position: fixed;
  top: 0;
  width: 1045px;
  width: 1069px;
  z-index: 16;
}

.tooltiptext {
  background-color: #333;
  border-radius: 5px;
  color: #fff;
  display: block;
  padding: 5px;
  position: fixed;
  text-align: center;
  width: 200px;
  z-index: 1;
}

.tooltiptext::after {
  border-color: #333 transparent transparent;
  border-style: solid;
  border-width: 5px;
  content: '';
  left: 50%;
  margin-left: -5px;
  position: absolute;
  top: 100%;
}

.alttooltiptext {
  background-color: #333;
  border-radius: 5px;
  color: #fff;
  display: block;
  padding: 5px;
  position: fixed;
  text-align: center;
  width: fit-content;
  z-index: 1;
}

.alttooltiptext::after {
  border-color: #333 transparent transparent;
  border-style: solid;
  border-width: 5px;
  content: '';
  left: 50%;
  margin-left: -5px;
  position: absolute;
  top: 100%;
}

.animateAnchor {
  background-color: #2d6ccad4;
  color: #fff;
}

.no-data {
  color: #808080;

  &:hover {
    background-color: rgba(255, 255, 255, 0) !important;
  }
}

.adsTooltipResponsive {
  min-width: 500px;
  @media screen and (max-width: 800px) {
    min-width: 350px !important;
  }
}

.font-adjust {
  @media screen and (max-width: 560px) {
    font-size: 12px;
  }
}

.shadow-sm {
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px !important;
}

.timePickerOk {
  .ant-picker-ok {
    margin-left: 0 !important;
  }
}

.paginationFix {
  position: absolute !important;
  z-index: 1 !important;
}


.position-change-range-picker {
  &:hover {
    border-color: #7f4ead !important;
    
    &.ant-calendar-picker-input.ant-input {
      border-color: #7f4ead !important;
      -webkit-box-shadow: 0 0 0 2px #7f4ead4e !important; /* stylelint-disable-line property-no-vendor-prefix */
      box-shadow: 0 0 0 2px #7f4ead4e !important;
      outline: 0 !important;
    }
  }
  
  &.ant-picker-focused {
    border-color: #7f4ead !important;
    -webkit-box-shadow: 0 0 0 2px #7f4ead4e !important; /* stylelint-disable-line property-no-vendor-prefix */
    box-shadow: 0 0 0 2px #7f4ead4e !important;
    outline: 0 !important;
  }
  
  .ant-picker-active-bar {
    background-color: #7f4ead !important;
  }

  .ant-picker-cell-in-view {
    &.ant-picker-cell-range-start,
    &.ant-picker-cell-range-end {
      .ant-picker-cell-inner {
        background-color: #7f4eaddd !important;
      }
    }

    &.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
    &.ant-picker-cell-in-range::before,
    &.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
      border-color: #7f4ead !important;
    }

    &.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
    &.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
      background: #7f4ead !important;
    }

    &.ant-picker-cell-in-range::before {
      background-color: #7f4ead53 !important;
    }

    .ant-picker-cell-inner {
      &::after {
        background-color: #7f4ead6d !important;
      }
    }
  }
  
  .ant-picker-time-panel-column {
    > li.ant-picker-time-panel-cell-selected {
      .ant-picker-time-panel-cell-inner {
        background: #e6ffe8 !important;
      }
    }
  }

  .ant-btn-primary {
    background-color: #7f4ead !important;
    border-color: #7f4ead !important;
  }

  .ant-picker-footer-extra {
    display: flex;
    justify-content: center;
  }
  
  .ant-picker-panel-container {
    box-shadow: unset !important;
  }

  .ant-picker-cell-in-view {
    &.ant-picker-cell-range-end,
    &.ant-picker-cell-range-start {
      .ant-picker-cell-inner {
        background-color: #7f4eaddd !important;
      }
    }
  }

  .ant-picker-cell-disabled::before {
    background-color: unset !important;
  }
  
}

.custom-popover {
  padding-bottom: unset !important;
}

#range-picker-wrapper > div { /* stylelint-disable-line */
  width: fit-content !important;
}

.summary {
  align-items: center;  /* stylelint-disable-line */
  width: 26px;  /* stylelint-disable-line */
  display: flex;  /* stylelint-disable-line */
  justify-content: center;  /* stylelint-disable-line */
  height: 26px;  /* stylelint-disable-line */
  border-top-left-radius: 19px;  /* stylelint-disable-line */
  border-top-right-radius: 19px;  /* stylelint-disable-line */
  border-bottom-right-radius: 19px;  /* stylelint-disable-line */
  margin-top: -6px;  /* stylelint-disable-line */
  margin-left: 6px;  /* stylelint-disable-line */
}
