@import 'src/style/colors';

.dropdown {
  align-items: center;
  cursor: pointer;
  display: flex;

  .avatar {
    border: 2px solid rgba(255, 255, 255, 0.65);
  }

  .down-icon {
    color: #a3a4a4;
    margin-left: 6px;

    svg {
      width: 10px;
    }
  }

  &:hover {
    .avatar {
      border: 2px solid rgba(255, 183, 226, 0.65);
    }

    .down-icon {
      svg {
        path {
          fill: $color-white;
        }
      }
    }
  }
}

.menu {
  left: calc(100% - 250px) !important;
  top: 78px !important;
  @media screen and (max-width: 768px) {
    max-height: 500px;
    overflow: scroll;
  }

  .modifiedItem {
    cursor: pointer;
    padding: 0 10px;
  }

  .tierButton {
    align-items: center;
    // background: rgba(70, 71, 74, 0.8);
    border-radius: 5px;
    color: #e8e8e8;
    display: flex;
    font-size: 12px;
    font-weight: 400;
    height: 50px;
    line-height: 15px;
    margin-bottom: 10px;
    padding-left: 12px;
    text-align: left;
    width: 100%;

    .tierText {
      color: #e8e8e8;
      font-size: 13px;
      font-weight: 500;
      line-height: 16px;
      text-align: left;
      text-transform: capitalize;
    }
  }

  // .tierButton:hover {
  //   background: rgba(70, 71, 74, 0.8);
  //   color: #e8e8e8;
  // }

  .menuBtn {
    color: $color-white;
    font-weight: 400;
    text-align: left;
    width: 100%;

    &:hover {
      color: $color-white;
    }

    :global(.anticon) {
      color: $color-icons;
    }
  }

  .zoomTextWithLink {
    color: #e8e8e8;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 400;
    padding: 5px 12px;

    a {
      color: #34aef3;
      font-family: 'Inter', sans-serif;
      font-size: 12px;
      font-weight: 400;
      text-decoration-line: underline;
    }
  }

  .zoomBtnWrapper {
    display: flex;
    flex-direction: column;
    padding: 5px 12px;
    
    .zoomBtnText {
      color: #e8e8e8;
      font-family: 'Inter', sans-serif;
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 8px;
      text-align: center;
    }

    .zoomBtn {
      align-items: center;
      background-color: #2ac155;
      border-color: transparent;
      border-radius: 6px;
      color: #fff;
      display: flex;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: 500;
      gap: 8px;
      justify-content: center;
      padding: 6px 15px !important;

      &:hover {
        background-color: rgba(42, 193, 85, 0.8);
      }
    }
  }

  .logout {
    color: $color-red;
    font-weight: 500;
    height: 100%;
    position: relative;
    text-align: center;
    width: 100%;
  }

  ul,
  li {
    background: #313337;
  }

  :global(.ant-dropdown-menu) {
    border-radius: 8px !important;
    min-width: 200px;
    padding: 10px 0 4px !important;
  }

  :global(.ant-dropdown-menu-item) {
    height: 48px;

    &:hover {
      background: rgba(127, 78, 173, 0.17);
    }

    &:active {
      background: rgba(127, 78, 173, 0.17);
    }

    &:focus {
      background: rgba(127, 78, 173, 0.17);
    }
  }

  :global(.ant-dropdown-menu-item-divider) {
    background: rgba(255, 255, 255, 0.1);
    margin: auto;
    width: 80%;
  }
}

.dropdownOffset {
  margin-top: -18px !important;
  @media screen and (max-width: 768px) {
    margin-top: -22px !important;
  }
}

.refferalIframe {
  background: #fff;
  border: 0;
  height: 500px;
  width: 100%;
}

.iconWrapper {
  font-size: 17px;
  height: 11px;
  margin-right: 9px;
  width: 14px;
}

.divider {
  background: #4e5156;
  height: 1px;
  margin: 10px;
}

.title {
  color: #a3a4a4;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  margin-left: 30px;
  text-transform: uppercase;
}
