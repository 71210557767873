@import 'src/style/colors';
@import 'src/style/fonts';

.mainContainer {
  background-color: $color-black-medium;
  height: 100vh;
}

.projectsAuthorizationContainer {
  background-color: transparent;
  margin: auto;
  padding: 50px;
  text-align: center;
  width: 578px;

  img {
    margin-bottom: 3rem;
  }

  h2 {
    color: $color-white-secondary;
    font-size: $font-size-14;
    font-style: normal;
    font-weight: 600;
  }

  p {
    color: $color-gray-light;
    font-size: $font-size-14;
    font-weight: normal;
    margin-bottom: 2rem;
  }

  button {
    background: $color-blue;
    color: $color-white;
    font-size: $font-size-14;
    font-weight: 500;
    width: 229px;
  }

  &.sitePropertyInvalidCredentials {
    width: fit-content;
  }

  &.noSitesConnectedBanner {
    button {
      background: transparent;
    }
  }
}

.chartBannerContainer {
  margin: auto;
  text-align: center;
  width: 80%;
}


.container {
  background-color: #24262a;
  height: auto !important;
  padding: 5px 30px;
  width: 100% !important;
}

.notificationBar {
  background: #dc702a !important;
  border-radius: 8px;
  color: #fff !important;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 72px;
  text-align: center;
  width: 100%;
}

.notificationBarRow {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 7px;
}

.notificationBarBlue {
  background: #144198 !important;
  border-radius: 8px;
  color: #fff !important;
  display: flex;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  padding: 15px 25px;
  position: relative;
  text-align: left;
  width: 100%;
}

.notificationBarRedFailed {
  background-color: #b62222 !important;
  background-image: url('/icons/dollarSign.svg'),;
  background-position: left;
  background-repeat: no-repeat;
  border-radius: 8px;
  color: #fff !important;
  display: flex;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  padding: 15px 25px;
  position: relative;
  text-align: left;
  width: 100%;
}

.notificationBarRed {
  background: $color-red-primary !important;
  border-radius: 8px;
  color: #fff !important;
  display: flex;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  padding: 15px 25px;
  position: relative;
  text-align: left;
  width: 100%;
}

.notificationBarOrange {
  background: #ce5d15 !important;
  border-radius: 8px;
  color: #fff !important;
  display: flex;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  padding: 15px 25px;
  position: relative;
  text-align: left;
  width: 100%;
}

.cancelBtn {
  background: transparent;
  border: 0;
  cursor: pointer;
}

.titleNCancelContainer {
  display: flex;
  justify-content: space-between;
}
