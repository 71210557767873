@import '@/style/colors';
/* stylelint-disable */
.DatePickerPopover {
  border-radius: 12px;
  padding-top: 0 !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.28);

  :global(.ant-popover-arrow) {
    display: none;
  }

  :global(.ant-popover-inner-content) {
    padding: 0 !important;
    width: auto !important;
  }
}

.TooltipOverlayClass {
  :global(.ant-tooltip-content) {
    :global(.ant-tooltip-inner) {
      background-color: $color-white;
      border: 1px solid $color-gray-light;
      border-radius: 8px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.48);
      color: #121212;
      font-size: 12px;
      position: relative;

      &::after {
        background-color: $color-white;
        border-left: 1px solid $color-gray-light;
        border-top: 1px solid $color-gray-light;
        content: '';
        height: 12px;
        left: calc(50% - 6px);
        position: absolute;
        top: -7px;
        transform: rotate(45deg);
        width: 12px;
      }
    }

    :global(.ant-tooltip-arrow) {
      :global(.ant-tooltip-arrow-content) {
        &::before {
          background-color: $color-white;
          --antd-arrow-background-color: $color-white;
          border-left: 1px solid $color-gray-light;
          border-top: 1px solid $color-gray-light;
        }
      }
    }
  }
}

.TooltipOverlayClassWithOffset {
  :global(.ant-tooltip-content) {
    :global(.ant-tooltip-inner) {
      background-color: $color-white;
      border: 1px solid $color-gray-light;
      border-radius: 8px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.48);
      color: #121212;
      font-size: 12px;
      position: relative;

      &::after {
        background-color: $color-white;
        border-left: 1px solid $color-gray-light;
        border-top: 1px solid $color-gray-light;
        content: '';
        height: 12px;
        left: calc(50% - 45px);
        position: absolute;
        top: -7px;
        transform: rotate(45deg);
        width: 12px;
      }
    }

    :global(.ant-tooltip-arrow) {
      :global(.ant-tooltip-arrow-content) {
        &::before {
          background-color: $color-white;
          --antd-arrow-background-color: $color-white;
          border-left: 1px solid $color-gray-light;
          border-top: 1px solid $color-gray-light;
        }
      }
    }
  }
}
/* stylelint-enable */

.DatePickerCalendarClass {
  // updates calendar header
  :global(.react-datepicker__header) {
    background-color: $color-white !important;
    border-bottom: 0 solid transparent;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    padding-top: 0 !important;
    position: relative;
 
  }

  &.DatePickerCalendarClassStart {
    :global(.react-datepicker__header) {
      &::before {
        background-color: $color-white;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        content: 'Choose a start date';
        height: 40px;
        left: 0;
        padding-top: 5px;
        position: absolute;
        top: -40px;
        width: 100%;
      }

      &::after {
        background-color: #e8e8e8;
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        top: -10px;
        width: 100%;
      }
    }
  }

  &.DatePickerCalendarClassEnd {
    :global(.react-datepicker__header) {
      &::before {
        background-color: $color-white;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        content: 'Choose an end date';
        height: 40px;
        left: 0;
        padding-top: 5px;
        position: absolute;
        top: -40px;
        width: 100%;
      }
  
      &::after {
        background-color: #e8e8e8;
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        top: -10px;
        width: 100%;
      }
    }
  }

  :global(.react-datepicker__day) {
    border: 0 solid transparent;
    border-radius: 0;
    margin: 1px !important;
    outline: none !important;
  }

  :global(.react-datepicker__day--in-selecting-range) {
    background-color: #7f4ead26;
    color: #121212;
  }
  
  :global(.react-datepicker__day--in-range) {
    background-color: #7f4ead26;
    color: #121212;
  }

  :global(.react-datepicker__day--range-start) {
    border: 1px solid #7f4ead !important; 
  }

  :global(.react-datepicker__day--range-end) {
    border: 1px solid #7f4ead !important; 
  }

  :global(.react-datepicker__day--range-start:not(.react-datepicker__day--in-selecting-range)) {
    background-color: #7f4ead;
    border-bottom-left-radius: 8px !important;
    border-top-left-radius: 8px !important;
  }

  :global(.react-datepicker__day--selecting-range-start) {
    background-color: #7f4ead;
    border-bottom-left-radius: 8px !important;
    border-top-left-radius: 8px !important;
  }

  :global(.react-datepicker__day--selecting-range-end) {
    background-color: #7f4ead;
    border-bottom-right-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }

  :global(.react-datepicker__day--outside-month) {
    color: #1212124d;
  }

  // fixes border radius to bottom part of caledar
  :global(.react-datepicker) {
    border-radius: 12px !important;
  }

  :global(.react-datepicker__current-month) {
    display: none;
  }

  :global(.react-datepicker) {
    margin-left: -7px;
  }

  :global(.react-datepicker__month-year-dropdown-container) {
    :global(.react-datepicker__month-year-dropdown) {
      background-color: $color-white !important;
      border-radius: 12px;
      height: fit-content;
      max-height: 230px;
      overflow-x: hidden;
      overflow-y: auto;
      width: 141px;
  
      :global(.react-datepicker__month-year-option) {
        border-radius: 8px;
        margin: 2px;
        padding-bottom: 3px;
        padding-top: 3px;

        &:hover {
          background-color: #7f4ead33;
        }
      }
        /* stylelint-disable */
      :global(.react-datepicker__month-year-option--selected_month-year) {
        background-color: #7f4ead33;
        border-radius: 8px;
        margin: 2px;
        padding-bottom: 3px;
        padding-top: 3px;

        :global(.react-datepicker__month-year-option--selected) {
          display: none;
        }
      }
       /* stylelint-enable */
    }
  }

  // updates UI for month year dropdown
  :global(.react-datepicker__month-year-read-view) {
    color: #121212 !important;
    font-size: 14px !important;
    visibility: visible !important;

    :global(.react-datepicker__month-year-read-view--down-arrow ) {
      border-color: #121212 !important;
      border-width: 2px 2px 0 0;
      height: 6px;
      top: 7px !important;
      width: 6px;
    }
  }

  :global(.react-datepicker__navigation-icon) {
    &::before {
      border-color: #121212 !important;
      border-width: 2px 2px 0 0;
    }

    
  }
}
