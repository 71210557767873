

.sliderStyle {
  :global(.ant-slider-handle) {
    --antd-wave-shadow-color: #f44343 !important;
    background-color: #2d6cca !important;
    border-color: #2d6cca !important;
    height: 16px !important;
    width: 16px !important;
  }
}

.masterLoaderWrapper {
  background: #1f2125;
  display: flex;
  height: calc(100vh - 66px);
  width: 100%;

  &.isFullPageMasterLoader {
    height: 100vh;
  }
}

.overlay {
  :global(.ant-popover-arrow) {
    display: none !important;
  }
}


.scaCountryDropdownContainer {
  :global(.ant-select-selection-item) {
    padding-top: 7px !important;
  }
}


.addKeywordDropdownStyle {
  :global(.ant-dropdown-menu-submenu-title) {
    &:hover {
      background: #2d6cca1a !important;
    }
  }

  :global(.ant-dropdown-menu-submenu-open) {
    background: #2d6cca1a !important;
  }
}

.addKeywordMenuStyle {
  :global(.ant-dropdown-menu-submenu-title) {
    &:hover {
      background: #2d6cca1a !important;
    }
  }

  :global(.ant-dropdown-menu-submenu-open) {
    background: #2d6cca1a !important;
  }
}

.rankTrackerDropdown {
  ul {
    max-height: 500px !important;
    overflow-y: scroll !important;
  }
}

// this styleshit is used exlisively for third party library style overriding which we can't do inline or through styled components

.CountryPickerPopover {
  border-radius: 12px !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.28) !important;
  padding-top: 0 !important;

  :global(.ant-popover-arrow) {
    display: none;
  }

  :global(.ant-popover-inner-content) {
    padding: 0;
  }
}
