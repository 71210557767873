@import '@/style/colors';
@import 'src/style/fonts';

.tabsContainer {
  /*  background: linear-gradient(180deg, #383542 0%, #25242b 100%); put this as a prop */
  border-radius: 5px;

  .header {
    padding-left: 12px;
  }

  .tabs {
    border: 0 !important;
    overflow: visible;

    :global(.rc-tabs-nav) {
      background: #24262a;
      height: 59px;
      padding: 0;
      padding-top: 6px;

    }


    :global(.rc-tabs-nav-operations) {
      background-color: #2d2f34 !important;
      border: 0;
      color: $color-white;
    }

    :global(.rc-tabs-nav-more) {
      background-color: #2d2f34 !important;
      border: 0;

      &::after {
        content: '→';
      }
    }

    :global(.rc-tabs-nav-wrap-ping-right) {
      &::after {
        border: 0;
      }
    }

    :global(.rc-tabs-nav-wrap-ping-left) {
      &::before {
        border: 0;
      }
    }

    :global(.rc-tabs-tab) {
      background: transparent;
      margin-right: 15px !important;
    }

    :global(.rc-tabs-content-holder) {
      background: $color-white;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    :global(.rc-tabs-ink-bar) {
      background: #f1aa3e;
    }

    :global(.rc-tabs-tab-btn) {
      border-radius: 5px;
      color: $color-non-primary-text;
      font-family: Inter;
      font-size: $font-size-14;
      font-style: normal;
      font-weight: 500;
      line-height: 175%;
      outline: none !important;
      padding: 5px 29px;
    }

    :global(.rc-tabs-tab-active .rc-tabs-tab-btn) {
      background: transparent;
      color: $color-white;
    }

    :global(.rc-tabs-extra-content) {
      align-items: center;
      display: flex;
    }
  }
}
