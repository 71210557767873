$color-black: #000;
$color-black-primary: #101013;
$color-black-secondary: #28282b;
$color-white: #fff;
$color-white-primary: #f9f9fb;
$color-white-secondary: #ddd;
$color-light-black: #4a4a4a;
$color-light-gray: #e8e8e8;
$color-dark-gray: #5f5f5f;
$color-gray: #979797;
$color-gray-medium: #222026;
$color-blue-gray: #9faac7;
$color-light-blue-gray: #ebeef5;

$color-blue: #2d6cca;
$color-link: #4881f0;
$color-link-primary: #81c7eb;
$color-link-secondary: #34aef3;
$color-link-latest: #34aef3;
$color-light-blue: #dae4f4;
$color-divider: #3e4043;

$color-green: #1aceb3;
$color-transparent-green: #e9fff2;
$color-text-on-transparent-green: #33bd6d;
$color-light-green: #2ac155;

$color-red: #ff5959;
$color-transparent-red: #ffeaea;
$color-text-on-transparent-red: #cb1213;

$color-orange: #ff8536;

$color-transparent-yellow: #fff2cc;
$color-text-on-transparent-yellow: #c1950e;

$color-table-strip: #f9fbff;

// new colors
$color-gray-dark: #121212;
$color-gray-light: #a3a4a4;
$color-gray-dark-secondary: #4e5156;
$color-gray-dark-medium: #2d2f34;
$color-non-primary-text: #e8e6ee;
$color-secondary-text: #77757d;
$color-icons: #9e9da1;
$color-border: #e5e6eb;
$color-border-primary: #424549;
$color-border-medium: #f3f3f3;
$color-black-medium: #24262a;

$color-menu-background: #19191b;
$color-navbar-background: #2e2c36;

$color-purple-medium: #7f4ead;
$color-teal: #478593;
$color-body-background: #f2f2f5;

$color-shadow: 0 3px 2px rgba(0, 0, 0, 0.02);
$color-shadow-hover: 0 4px 20px rgba(0, 0, 0, 0.15);
$color-light-gray: #dedede;
$color-light-gray-primary: #f6f6f8;
$color-light-gray-secondary: #696777;
$color-light-gray-third: #e8e8e8;
$color-light-gray-forth: #585858;
$color-light-gray-fifth: #37393e;

$color-green: #1d754e;
$color-light-green: #a2d799;
$color-pale-green: #52d477;
$color-orange: #e4a469;
$color-orange-primary: #f1aa3e;
$color-red: #ff6262;
$color-red-primary: #f44343;
$color-red-secondary: #ff4434;
$color-pink: #f58069;
$color-link-blue: #2e6cca;

$color-blurred-blue: #34aef3;

$color-loader-gray: #7e8184;
$color-loader-gray-light: #ececec;
$color-icon-gray: #424448;

$color-background-dark: #24262a;

$color-cyan-secondary: #00d1ed;
